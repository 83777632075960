var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mx-0",class:{
    'pointer-events-none':
      _vm.preventiveId > 0 && _vm.preventiveEditData?.preventive?.visit_create_flag,
  }},[_c('v-col',{staticClass:"py-0",attrs:{"md":_vm.vPShowCalendar ? 4 : 12}},[_c('v-row',[_c('v-col',{attrs:{"md":_vm.vPShowCalendar ? 12 : 12}},[_c('v-row',{},[_c('v-col',{staticClass:"light-gray-background custom-border-bottom d-flex justify-space-between",attrs:{"md":"12"}},[_c('v-spacer'),_c('div',[_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"disabled":_vm.prventiveLoading,"color":"cyan","right":"","small":"","depressed":""},on:{"click":function($event){return _vm.updateCalendar()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-calendar-blank")]),(_vm.vPShowCalendar)?_c('span',[_vm._v("Hide Calendar")]):_c('span',[_vm._v("Show Calendar")])],1)],1)],1),_c('v-col',{attrs:{"md":_vm.vPShowCalendar ? 12 : 12}},[_c('v-select',{staticClass:"schedule-type-list",class:_vm.vPShowCalendar ? '' : 'custom-width-padding',attrs:{"dense":"","filled":"","disabled":_vm.prventiveLoading,"item-color":"cyan","items":_vm.recurringPScheduleTypeList,"placeholder":"Schedule Type","solo":"","flat":"","value":_vm.prventiveRecurringSchedule.type,"return-object":"","hide-details":"","color":"cyan"},on:{"change":function($event){return _vm.updateWeekDays($event)}}}),(_vm.prventiveRecurringSchedule?.type?.group == 'monthly')?_c('div',{staticClass:"mt-2"},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"disabled":_vm.prventiveLoading,"hide-details":"","mandatory":"","value":_vm.prventiveRecurringSchedule.recurring_pattern},on:{"change":function($event){_vm.updateScheduleState(
                    'dbPRecurringSchedule.recurring_pattern',
                    $event
                  );
                  _vm.updateScheduleState(
                    'dbPRecurringSchedule.force_update',
                    false
                  );}}},[_c('v-radio',{attrs:{"disabled":_vm.prventiveLoading,"value":1,"hide-details":"","color":"cyan"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex align-center font-weight-500",class:{
                        'disabled-rw pointer-events-none':
                          _vm.prventiveRecurringSchedule.recurring_pattern != 1,
                      }},[_c('label',{staticClass:"text-center my-0 ml-2"},[_vm._v("Selection by Date")])])]},proxy:true}],null,false,457588738)}),_c('v-radio',{attrs:{"disabled":_vm.prventiveLoading,"value":2,"hide-details":"","color":"cyan"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex align-center font-weight-500",class:{
                        'disabled-rw pointer-events-none':
                          _vm.prventiveRecurringSchedule.recurring_pattern != 2,
                      }},[_c('div',{staticStyle:{"width":"150px"}},[_c('v-select',{attrs:{"dense":"","filled":"","disabled":_vm.prventiveLoading,"item-color":"cyan","items":_vm.durationList,"item-text":"long_name","item-value":"id","placeholder":"Select Day","solo":"","flat":"","hide-details":"","color":"cyan"},on:{"change":function($event){return _vm.updateSchedule()}},model:{value:(_vm.prventiveRecurringSchedule.week_day),callback:function ($$v) {_vm.$set(_vm.prventiveRecurringSchedule, "week_day", $$v)},expression:"prventiveRecurringSchedule.week_day"}})],1),_c('div',{staticStyle:{"width":"150px"}},[_c('v-select',{attrs:{"dense":"","filled":"","disabled":_vm.prventiveLoading,"item-color":"cyan","item-text":"long_name","item-value":"id","items":_vm.PWeekDays,"placeholder":"Select Week Day","solo":"","flat":"","hide-details":"","color":"cyan"},on:{"change":function($event){return _vm.updateSchedule()}},model:{value:(_vm.prventiveRecurringSchedule.week_count),callback:function ($$v) {_vm.$set(_vm.prventiveRecurringSchedule, "week_count", $$v)},expression:"prventiveRecurringSchedule.week_count"}})],1),(
                          _vm.prventiveRecurringSchedule?.type?.value ==
                          'twice_a_month'
                        )?[_c('div',{staticClass:"ml-10",staticStyle:{"width":"150px"}},[_c('v-select',{attrs:{"dense":"","filled":"","disabled":_vm.prventiveLoading,"item-color":"cyan","items":_vm.durationList,"item-text":"long_name","item-value":"id","placeholder":"Select Day","solo":"","flat":"","hide-details":"","color":"cyan"},on:{"change":function($event){return _vm.updateSchedule()}},model:{value:(
                              _vm.prventiveRecurringSchedule.second_week_day
                            ),callback:function ($$v) {_vm.$set(_vm.prventiveRecurringSchedule, "second_week_day", $$v)},expression:"\n                              prventiveRecurringSchedule.second_week_day\n                            "}})],1),_c('div',{staticStyle:{"width":"150px"}},[_c('v-select',{attrs:{"dense":"","filled":"","disabled":_vm.prventiveLoading,"item-color":"cyan","item-text":"long_name","item-value":"id","items":_vm.PWeekDays,"placeholder":"Select Week Day","solo":"","flat":"","hide-details":"","color":"cyan"},on:{"change":function($event){return _vm.updateSchedule()}},model:{value:(
                              _vm.prventiveRecurringSchedule.second_week_count
                            ),callback:function ($$v) {_vm.$set(_vm.prventiveRecurringSchedule, "second_week_count", $$v)},expression:"\n                              prventiveRecurringSchedule.second_week_count\n                            "}})],1)]:_vm._e()],2)]},proxy:true}],null,false,3135405706)})],1)],1):_vm._e(),(_vm.prventiveRecurringSchedule?.type?.group == 'weekly')?_c('v-row',_vm._l((_vm.PWeekDays),function(weekday,index){return _c('v-col',{key:`job-weekday-${index}`,staticClass:"py-0 my-0",attrs:{"md":"2"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"cyan","disabled":_vm.prventiveLoading || _vm.isDisabled(weekday.id),"label":_vm.vPShowCalendar ? weekday.short_name : weekday.long_name,"input-value":+_vm.prventiveRecurringSchedule.weeks.includes(weekday.id),"true-value":1,"false-value":0,"hide-details":""},on:{"change":function($event){return _vm.updateSelection(weekday.id)}}})],1)}),1):_vm._e()],1),_c('v-col',{attrs:{"md":_vm.vPShowCalendar ? 12 : 4}},[_c('label',{staticClass:"ml-2",attrs:{"for":"visit-one-off-start-date"}},[_vm._v("Start Date")]),_c('DatePicker',{key:"visit-one-off-start-date",attrs:{"disabled":_vm.prventiveLoading,"value":_vm.prventiveRecurringSchedule.start_date,"id":"visit-one-off-start-date","placeholder":"Start Date"},on:{"change":function($event){_vm.updateScheduleState(
                  'dbPRecurringSchedule.start_date',
                  $event
                );
                _vm.updateEndDate(true);
                _vm.updateScheduleState(
                  'dbPRecurringSchedule.force_update',
                  false
                );}}})],1),_c('v-col',{attrs:{"md":_vm.vPShowCalendar ? 12 : 4}},[_c('label',{staticClass:"ml-2",attrs:{"for":"visit-one-off-start-time"}},[_vm._v("Start Time")]),_c('TimePicker',{key:"visit-one-off-start-time",attrs:{"id":"visit-one-off-start-time","placeholder":"Start Time","type":"start-time","value":_vm.prventiveRecurringSchedule.start_time,"min-time":_vm.todayTime},on:{"change":function($event){return _vm.updateScheduleState('dbPRecurringSchedule.start_time', $event)}}})],1),_c('v-col',{attrs:{"md":_vm.vPShowCalendar ? 12 : 4}},[_c('label',{staticClass:"ml-2",attrs:{"for":"visit-one-off-end-time"}},[_vm._v("End Time")]),_c('TimePicker',{key:"visit-one-off-end-time",attrs:{"disabled":_vm.prventiveLoading,"id":"visit-one-off-end-time","placeholder":"End Time","type":"end-time","intervel":60,"value":_vm.prventiveRecurringSchedule.end_time,"min-time":_vm.updatedDate},on:{"change":function($event){_vm.updateScheduleState('dbPRecurringSchedule.end_time', $event);
                _vm.updateScheduleState(
                  'dbPRecurringSchedule.force_update',
                  false
                );}}})],1),_c('v-col',{attrs:{"md":_vm.vPShowCalendar ? 12 : 5}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"disabled":_vm.prventiveLoading,"hide-details":"","intervel":60,"mandatory":"","value":_vm.prventiveRecurringSchedule.end_mode},on:{"change":function($event){_vm.updateScheduleState('dbPRecurringSchedule.end_mode', $event);
                _vm.updateScheduleState(
                  'dbPRecurringSchedule.force_update',
                  false
                );}}},[_c('v-radio',{staticStyle:{"max-width":"375px !important"},attrs:{"disabled":_vm.prventiveLoading,"value":2,"hide-details":"","color":"cyan"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex align-center",class:{
                      'disabled-rw pointer-events-none':
                        _vm.prventiveRecurringSchedule.end_mode != 2,
                    }},[_c('div',[_vm._v("End after")]),_c('div',{staticClass:"mx-2"},[_c('v-text-field',{attrs:{"dense":"","disabled":_vm.prventiveLoading ||
                          _vm.prventiveRecurringSchedule.end_mode != 2,"filled":"","value":_vm.prventiveRecurringSchedule.occurrence,"type":"number","placeholder":"occurrences","solo":"","flat":"","color":"cyan"},on:{"change":function($event){_vm.updateScheduleState(
                            'dbPRecurringSchedule.occurrence',
                            $event
                          );
                          _vm.updateScheduleState(
                            'dbPRecurringSchedule.force_update',
                            false
                          );}}})],1),_c('div',[_vm._v("occurrences")])])]},proxy:true}])}),_c('v-radio',{staticStyle:{"max-width":"375px !important"},attrs:{"disabled":_vm.prventiveLoading,"value":3,"hide-details":"","color":"cyan"},on:{"click":function($event){return _vm.updateEndDate(true)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex align-center",class:{
                      'disabled-rw pointer-events-none':
                        _vm.prventiveRecurringSchedule.end_mode != 3,
                    }},[_c('div',{staticClass:"mr-2"},[_vm._v("End after")]),_c('DatePicker',{key:"visit-recurring-end-date",attrs:{"disabled":_vm.prventiveLoading ||
                        _vm.prventiveRecurringSchedule.end_mode != 3,"value":_vm.prventiveRecurringSchedule.end_mode == 3
                          ? _vm.prventiveRecurringSchedule.end_date
                          : '',"min-date":_vm.prventiveRecurringSchedule.start_date,"placeholder":"Select End Date"},on:{"change":function($event){_vm.updateScheduleState(
                          'dbPRecurringSchedule.end_date',
                          $event
                        );
                        _vm.updateScheduleState(
                          'dbPRecurringSchedule.force_update',
                          false
                        );}}})],1)]},proxy:true}])})],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('table',{attrs:{"width":"100%"}},[_c('tr',[_c('td',{staticClass:"m-0 px-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"},[_vm._v(" "+_vm._s(_vm.prventiveRecurringSchedule.message)+" ")]),_c('td',{staticClass:"px-0 py-0",attrs:{"width":"150px"}},[(_vm.preventiveScheduleOutput.length)?_c('v-btn',{staticClass:"custom-bold-button",style:(`min-height: ${
                      _vm.vPShowCalendar ? '56px' : '36.5px'
                    }; width: 100%;`),attrs:{"disabled":_vm.prventiveLoading || _vm.loadingDialog,"loading":_vm.loadingDialog,"color":"cyan white--text","depressed":""},on:{"click":function($event){return _vm.openDialog()}}},[_vm._v(" Show Schedule ")]):_c('v-btn',{staticClass:"custom-bold-button",style:(`min-height: ${
                      _vm.vPShowCalendar ? '56px' : '36.5px'
                    }; width: 100%;`),attrs:{"disabled":_vm.prventiveLoading || _vm.loadingDialog,"loading":_vm.loadingDialog,"color":"cyan white--text","depressed":""},on:{"click":function($event){return _vm.showSchedule()}}},[_vm._v(" Get Schedule ")])],1)])])])],1)],1)],1),_c('ConfirmSchedule',{key:`job-confirm-schedule-${_vm.uniqueCId}`,attrs:{"dialog":_vm.scheduleConfirmDialog},on:{"close":function($event){_vm.scheduleConfirmDialog = false}}})],1),(_vm.vPShowCalendar)?_c('v-col',{attrs:{"md":"8"}},[_c('Calendar',{key:`job-recurring-calendar-${_vm.scheduleKey}`,attrs:{"show-calendar":_vm.vPShowCalendar}})],1):_vm._e(),(_vm.scheduleDialog)?_c('ShowSchedule',{key:`job-show-schedule-${_vm.uniqueId}`,attrs:{"dialog":_vm.scheduleDialog},on:{"close":function($event){_vm.scheduleDialog = false},"next":function($event){return _vm.nextStepSchedule()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }